/**
 * @generated SignedSource<<c5f354d122eb0d63928390e375bb92fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlockExplorerName = "ARBISCAN" | "BASESCAN" | "BLASTSCAN" | "BLOCKSCOUT" | "BSCSCAN" | "ETHERSCAN" | "GUNZSCAN" | "KLAYTNSCOPE" | "OKLINK" | "OPTIMISTIC_ETHERSCAN" | "POLYGONSCAN" | "ROUTESCAN" | "SEITRACE" | "SNOWTRACE" | "SOLANA_EXPLORER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CollectionBannerActionBar_data$data = {
  readonly assetContracts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly blockExplorerLink: string;
        readonly chainData: {
          readonly blockExplorer: {
            readonly identifier: BlockExplorerName;
            readonly name: string;
          };
        };
      } | null;
    } | null>;
  };
  readonly connectedInstagramUsername: string | null;
  readonly connectedTwitterUsername: string | null;
  readonly discordUrl: string | null;
  readonly externalUrl: string | null;
  readonly instagramUsername: string | null;
  readonly mediumUsername: string | null;
  readonly relayId: string;
  readonly representativeAsset: {
    readonly assetContract: {
      readonly openseaVersion: string | null;
    };
  } | null;
  readonly slug: string;
  readonly telegramUrl: string | null;
  readonly twitterUsername: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionWatchlistButton_data" | "collection_url">;
  readonly " $fragmentType": "CollectionBannerActionBar_data";
};
export type CollectionBannerActionBar_data$key = {
  readonly " $data"?: CollectionBannerActionBar_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionBannerActionBar_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionBannerActionBar_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "representativeAsset",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetContractType",
          "kind": "LinkedField",
          "name": "assetContract",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "openseaVersion",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        }
      ],
      "concreteType": "AssetContractTypeConnection",
      "kind": "LinkedField",
      "name": "assetContracts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetContractTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "blockExplorerLink",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChainType",
                  "kind": "LinkedField",
                  "name": "chainData",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BlockExplorerType",
                      "kind": "LinkedField",
                      "name": "blockExplorer",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "identifier",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "assetContracts(first:2)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discordUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instagramUsername",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediumUsername",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "telegramUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "twitterUsername",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectedInstagramUsername",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectedTwitterUsername",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionWatchlistButton_data"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "61c5f8b3cd8734d12b18847286ec9424";

export default node;
